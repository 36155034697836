// validates a vehicle VIN number (chassis number)
export default function validate(vin) {
  if (!vin) return false;

  // Remove any whitespace and convert to uppercase
  vin = vin.replace(/\s/g, '').toUpperCase();

  // Check if the length is exactly 17 characters
  if (vin.length !== 17) return false;

  // Check if the last 4 characters are numbers
  if (!/^\d{4}$/.test(vin.slice(-4))) return false;

  // Check if the VIN contains I, O, or Q
  if (/[IOQ]/.test(vin)) return false;

  // Additional checks can be added here if needed

  return true;
}